import * as React from 'react';
import DataProtectionForm from '../components/dataProtectionForm';
import SEO from '../components/seo';
import T from '../components/T';
import Layout from '../layouts/default';

import { ContainerMaxWidth } from '@hypersay/ui';
import * as styles from './data-protection.module.scss';

const choices = [
  {
    code: 'pleaseSelect',
    title: ' - please select an action from this list -',
    body: (
      <small>
        <em>
          <T id="Select an option from the box above." />
        </em>
      </small>
    ),
  },
  {
    code: 'learnAbout',
    title: 'learn about Hypersay and personal data',
    body: (
      <>
        <p>
          <T id="We have a detailed description of everything related to personal data collection and processing in our Privacy Policy page. Click on the link below to visit the page." />
        </p>
        <p>
          <a href="/privacy/">
            <T id="Privacy Policy" />
          </a>
        </p>
      </>
    ),
  },
  {
    code: 'seeWhoHypersayIs',
    title: 'see who is Hypersay',
    body: (
      <>
        <p>
          <em>Hypersay</em> <T id="is created and operated by:" />
        </p>
        <p>
          <strong>Learn Forward Ltd.,</strong>
          <br />
          71 Queen Victoria Street,
          <br />
          London, England, EC4V 4BE
          <br />
        </p>
        <p>
          <T id="To learn about company structure, its directors and other official records, visit the Companies House website and search company number 09401061" />{' '}
          (
          <a
            href="https://beta.companieshouse.gov.uk/company/09401061"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            <T id="direct link" />
          </a>
          ).
        </p>
        <p>
          <T id='Contact us via live web chat (the button in the bottom right). Or, if you wish for something more formal, please use the "talk to Hypersay Data Protection Officer" option in the menu above. You could also check our Privacy Policy page.' />
        </p>
        <p>
          <a href="/privacy/">
            <T id="Privacy Policy" />
          </a>
        </p>
      </>
    ),
  },
  {
    code: 'requestMyData',
    title: 'request a copy of the personal data you have on me',
    body: (
      <>
        <p>
          <T id="We are happy to send you a human readable copy of the personal data we have on you, such as your name, e-mail and other information (questions you asked, notes you took, etc.)." />
        </p>
        <p>
          <T id='If you are interested in the nature of the data we collect and process, please read the "What personal data we collect, why and for how long" section of our Privacy Policy page, clicking the link below. Or use the form below to request the actual data.' />
        </p>
        <p>
          <a href="/privacy/">
            <T id="Privacy Policy" />
          </a>
        </p>
        <br />
        <DataProtectionForm
          request="requestMyData"
          submitLabel="Request a copy in human readable format"
          mustBeSignedIn={true}
        />
      </>
    ),
  },
  {
    code: 'receiveACopyInMachineFormat',
    title: 'request a copy of my data in machine readable format',
    body: (
      <>
        <p>
          <T id="We are happy to send you a machine-readable copy of the personal data we have on you, for backup or portability reasons. We will send you an archive as an e-mail attachment to your registered address. It usually takes a couple of business days, but it might take longer if you have a lot of data or we are extremely busy." />
        </p>
        <p>
          <T id='If you are rather interested in the nature of the data we collect and process, please read the "What personal data we collect, why and for how long" section of our Privacy Policy page, clicking the link below. Or use the form below to request the actual data.' />
        </p>
        <p>
          <a href="/privacy/">
            <T id="Privacy Policy" />
          </a>
        </p>
        <br />
        <DataProtectionForm
          request="receiveACopyInMachineFormat"
          submitLabel="Request a copy in machine format"
          mustBeSignedIn={true}
        />
      </>
    ),
  },
  {
    code: 'askForRectification',
    title: 'ask Hypersay to rectify my personal data',
    body: (
      <>
        <p>
          <T id="We are happy to correct any error. Please let us know about it and we will fix it as soon as we can. You will get a confirmation, too." />
        </p>
        <br />
        <DataProtectionForm
          request="askForRectification"
          submitLabel="Ask for rectification"
          mustBeSignedIn={true}
        />
      </>
    ),
  },
  {
    code: 'revokeConsent',
    title: 'revoke my consent for personal data processing',
    body: (
      <>
        <p>
          <T id="We use the personal data we collect in order to provide Hypersay services (e.g. your dashboard, your sessions, your preferred language, etc.). If you have a concern or complaint about how we use your personal data or about our personal data policies in general, please try discussing it with us first and we will find a good solution." />
        </p>
        <p>
          <T id="There is a live web chat button on the bottom right of this page and an option to contact the data protection responsible in the menu above." />
        </p>
        <p>
          <T id="Revoking your consent to process your personal data will result in your account being deleted, an irreversible operation, that cannot be undone even if you do have a copy of your data from us." />
        </p>
        <p>
          <T
            id={`
            Please consider adjusting your options or unsubscribe from e-mails
            that are irrelevant for you instead.`}
          />
        </p>
        <p>
          <T
            id={`
            If revoking the consent is what you want to do, please use the form
            below to proceed.`}
          />
        </p>
        <br />
        <DataProtectionForm
          request="revokeConsent"
          submitLabel="Revoke your data processing consent"
          mustBeSignedIn={true}
        />
      </>
    ),
  },
  {
    code: 'askForErasure',
    title: 'ask Hypersay to permanently erase my personal data',
    body: (
      <>
        <p>
          <T id="We are sorry to see you go. If you have a concern or complaint about how we use your personal data (or personal data in general), please try discussing it with us first." />
        </p>
        <p>
          <T id="There is a live web chat button on the bottom right of this page and an option to contact the data protection responsible in the menu above." />
        </p>
        <p>
          <T id="Since this is a irreversible operation, we need to ensure that you are the proper owner of the account and the data you request to be erased. Once the data is gone, it cannot be restored by anybody, even if you requested a backup from us." />
        </p>
        <br />
        <DataProtectionForm
          request="askForErasure"
          submitLabel="Request erasure of your data"
          mustBeSignedIn={true}
        />
      </>
    ),
  },
  {
    code: 'complain',
    title: 'complain about Hypersay personal data management',
    body: (
      <>
        <p>
          <T id="If you are unhappy with our way of handling your personal data, you have the right to lodge a complaint with a supervisory authority." />
        </p>
        <p>
          <T id=" However, we would be grateful to learn more about your concern before you file the complaint since we might be able to resolve the problem immediately. Let us know what it is about using the chat button on the bottom right hand corner of this page or the form below." />
        </p>
        <p>
          <T id="We have a detailed description of everything related to personal data collection and processing in our Privacy Policy page. Click on the link below to visit the page." />
        </p>
        <p>
          <a href="/privacy/">
            <T id="Privacy Policy" />
          </a>
        </p>
        <DataProtectionForm
          request="complain"
          submitLabel="Send us your concern"
        />
      </>
    ),
  },
  {
    code: 'talkToDPO',
    title: "talk to Hypersay's Data Protection Officer",
    body: (
      <>
        <p>
          <T id="Please use the form below to contact the person responsible with data protection for Hypersay. You will get a reply as soon as possible." />
        </p>
        <DataProtectionForm request="talkToUs" submitLabel="Send message" />
      </>
    ),
  },
];

const IWantToDropdown: React.FC<{
  selected: number;
  onChange: (x: number) => void;
}> = ({ selected, onChange }) => (
  <select
    className={styles.iWantToDropdown}
    value={selected}
    onChange={(e) => onChange(parseInt(e.target.value, 10))}
  >
    {choices.map(({ code, title }, i) => (
      <option key={code} value={i}>
        {title}
      </option>
    ))}
  </select>
);

export const Gdpr: React.FC<{}> = () => {
  const [choice, setChoice] = React.useState<number>(0);

  const onChange = (newChoice: number) => {
    setChoice(newChoice);
  };

  const { body } = choices[choice];

  return (
    <Layout>
      <SEO title="GDPR - Hypersay" />
      <ContainerMaxWidth maxWidth="50em">
        <div className={styles.wrapper}>
          <div className={styles.topWrapper}>
            <div className={styles.container}>
              <div className={styles.content}>
                <h1>
                  <T id="Data Protection / GDPR" />
                </h1>
                <p>
                  <T id="This is a special page where you can exercise your data rights, as highlighted and required by GDPR." />
                </p>
                <div className={styles.iWantTo}>
                  <T id="I want to:" />
                  <IWantToDropdown selected={choice} onChange={onChange} />
                </div>
                <div className={styles.sectionBody}>{body || ''}</div>
              </div>
            </div>
          </div>
        </div>
      </ContainerMaxWidth>
    </Layout>
  );
};

export default Gdpr;
