import React from 'react';

interface User {
  username: string;
  name: { first: string; last: string };
}

interface State {
  user?: User;
}

import T from '../components/T';
import navigateToAppSite from '../util/navigate-to-app-site';

import styles from './dataProtectionForm.module.scss';

const isSignedIn = (): string => {
  if (typeof window === 'undefined') {
    return '';
  }

  // @ts-ignore
  const state = window.state as State;
  return (state && state.user && state.user.username) || '';
};

const fetchPost = (body: { service: string; email: string; body: string }) =>
  fetch('http://localhost:1992/gdpr', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

const PleaseSignIn = () => (
  <div className={styles.container}>
    <div>
      <div className={styles.title}>
        <T id="Please Sign In" />
      </div>
      <div className={styles.subtitle}>
        <T
          id={`
          We need to make sure it is you who submits this form. So please Sign
          In to continue
        `}
        />
      </div>
    </div>
    <div className={styles.buttons}>
      <button
        className={styles.signinButton}
        onClick={() => {
          if (typeof window === 'undefined') {
            return;
          }
          navigateToAppSite('login/st/data-protection');
        }}
      >
        <T id="Sign in" />
      </button>
    </div>
  </div>
);

const validateEmail = (e: string) =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    e,
  );

interface FormProps {
  username: string;
  submitLabel: string;
  request: string;
  onSubmit: (params: any) => void;
}

const Form = ({ username, request, onSubmit, submitLabel }: FormProps) => {
  let email = username;
  let submitButton: HTMLInputElement | null;
  let messageTextarea: HTMLTextAreaElement | null;

  const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isValid = validateEmail(e.target.value);
    if (isValid) {
      email = e.target.value;
    }
    if (submitButton) {
      submitButton.disabled = !isValid;
    }
  };

  const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit({
      email,
      request,
      message: messageTextarea ? messageTextarea.value : 'NO MESSAGE',
    });

    return !!username;
  };

  return (
    <form onSubmit={onFormSubmit} className={styles.form}>
      <label htmlFor="email">
        <T id="Your email:" />
      </label>
      <input
        name="email"
        type="email"
        defaultValue={email}
        disabled={!!email}
        onChange={onTextChange}
      />
      <label htmlFor="message">
        <T id="Your message or information to help us:" />
      </label>
      <textarea
        name="message"
        ref={(r) => {
          messageTextarea = r;
        }}
        rows={3}
      />
      <br />
      <br />
      <input
        type="submit"
        value={submitLabel}
        disabled={!email}
        ref={(r) => {
          submitButton = r;
        }}
      />
    </form>
  );
};

export interface DataProtectionFormProps {
  request: string;
  submitLabel: string;
  username?: string;
  signedIn?: boolean;
  mustBeSignedIn?: boolean;
  onSignin?: () => any;
  onEmailSignin?: () => any;
  onSocialLogin?: () => any;
  onSubmit?: () => any;
  reset?: () => void;
  sendingStatus?: 'ready' | 'sending' | 'sent' | 'error';
}

const DataProtectionForm = (props: DataProtectionFormProps) => {
  const onReset = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    if (props.reset) {
      props.reset();
    }
  };

  const onSubmit = (data: {
    request: string;
    email: string;
    message: string;
  }) => {
    switch (data.request) {
      case 'requestMyData':
        return fetchPost({
          service: 'DATA_DUMP',
          email: data.email,
          body: data.message,
        });

      case 'receiveACopyInMachineFormat':
        return fetchPost({
          service: 'DATA_DUMP_MACHINE_READABLE',
          email: data.email,
          body: data.message,
        });

      case 'askForRectification':
        return fetchPost({
          service: 'DATA_RECTIFICATION',
          email: data.email,
          body: data.message,
        });

      case 'revokeConsent':
        return fetchPost({
          service: 'CONSENT_REVOKE',
          email: data.email,
          body: data.message,
        });

      case 'askForErasure':
        return fetchPost({
          service: 'DATA_REMOVE',
          email: data.email,
          body: data.message,
        });

      case 'complain':
        return fetchPost({
          service: 'DATA_PROTECTION_COMPLAINT',
          email: data.email,
          body: data.message,
        });

      case 'talkToUs':
        return fetchPost({
          service: 'DATA_CONTACT',
          email: data.email,
          body: data.message,
        });

      default:
        return null;
    }
  };

  const renderForm = (username: string): React.ReactNode => {
    // @ts-ignore
    const { request, submitLabel, sendingStatus } = props;
    switch (sendingStatus) {
      case 'error':
        return (
          <p>
            <T id="An error occurred while processing your request." />{' '}
            <a href={`${process.env.HYPERSAY_FRONTEND_APP_API}/data-protection/`} onClick={onReset}>
              <T id="Try again" />
            </a>{' '}
            <T id="or contact us" />
          </p>
        );
      case 'sent':
        return (
          <p>
            <T id="Thank you! You will hear from us soon." />
          </p>
        );
      case 'sending':
        return (
          <React.Fragment>
            <Form
              request={request}
              username={username}
              onSubmit={onSubmit}
              submitLabel={submitLabel}
            />
          </React.Fragment>
        );
      case 'ready':
      default:
        return (
          <Form
            request={request}
            username={username}
            onSubmit={onSubmit}
            submitLabel={submitLabel}
          />
        );
    }
  };

  const { mustBeSignedIn = false } = props;

  return mustBeSignedIn && !isSignedIn() ? (
    <PleaseSignIn />
  ) : (
    <div className={styles.formContainer}>{renderForm(isSignedIn() || '')}</div>
  );
};

export default DataProtectionForm;
